import React from 'react'
import { InfoCircleOutlined, CopyOutlined, LikeOutlined, DislikeOutlined, DownOutlined, RetweetOutlined, ReloadOutlined } from '@ant-design/icons';
import { Avatar, Button, message, Collapse,Panel } from 'antd';
import BotAvt from '../../images/chatbot_avatar.png';
import ChatForm from '../chat/chatFrom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
// import FilePreview from "../components/filePreview";
import RcViewer from '@hanyk/rc-viewer';
import LazyImage from './lazyImage';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {coldarkDark} from 'react-syntax-highlighter/dist/esm/styles/prism';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import fetch from '../../fetch/axios';

class QAModule extends React.Component {
  state = {
    data: {},
    index: undefined,
    imgLoadedArr: [], // 图片懒加载完成

    isHistory: false, // 是否是历史记录

    isLike: 0, // 是否点赞
    badReson: "", // 差评原因
    feedback: {}, // 反馈信息


    showAllGuider: false, // 是否展开所有guider
  }
  componentDidMount() {
    const { data, index, isHistory } = this.props;
    this.setState({
      data,
      index,
      isHistory: isHistory||false,
      feedback: JSON.parse(JSON.stringify(data.feedback || {}))
    });
  }

    reGenerateAnswer() {

        this.props.reGenerate();

    }


  msgClicked(e, row) {
    // console.log(e, row)
    let ele = e.target;
    if (ele && ele.tagName === "SUP" && ele.id && row?.source_files) {
      let index = parseInt(ele.id) - 1;
      let fileArr = row.source_files.sort((a,b)=>a.index - b.index)[index];
      if (fileArr) {
        this.props.viewFile?.(fileArr);
      }
    }
  }

  // 获取文件信息
  getFileInfo(obj) {
    if (obj.getFile) {
      obj.getFile = false;
    } else {
      obj.getFile = true;
    }
    this.setState({data:obj})
  }

  // 图片懒加载完成数量
  onLoaded(src) {
    let imgLoadedArr = this.state.imgLoadedArr;
    if (imgLoadedArr.includes(src)) return;
    imgLoadedArr.push(src);
    this.setState({imgLoadedArr})
  }

  setLike(flag) {
    let { isHistory, data } = this.state;
    if (isHistory || data.isFeedBack) return;
    let feedback = {
      "feedback_type": flag,
      "feedback_id": data.id,
      "remark": "",
      "feedback_Reason": ""
    };

    this.setState({feedback},()=>{
      this.saveFeedback();
    })
  }
  setFeedBack(value, key) {

    let { isHistory, data } = this.state;
    if (isHistory || data.feedback?.remark?.trim() || data.feedback?.feedback_Reason?.trim()) return;

    let { feedback } = this.state;
    feedback[key] = value;
    this.setState({feedback})
  }

    showLinks(e) {
        e.currentTarget.nextElementSibling.style.display = 'block'
    }

  saveFeedback() {
    let { data, feedback } = this.state;
    feedback.Project_Id = `${data.bot_ID}`;
    feedback.userId= this.props.uId || "";
    feedback.userName= this.props.uName || "";


    // 接口
    fetch.post(`/api/chat/feedback`, feedback).then(res => {
      if (res && res.success) {
        message.success('Feedback saved successfully.');
        data.isFeedBack = true;
        data.feedback = JSON.parse(JSON.stringify(feedback));
        this.setState({data});
      }
    })
  }

  render() {
    const { data, feedback, isHistory } = this.state;

    const { user } = this.props.reducer;
    const { projectType, t } = this.props;
    const options = {
      // 是否显示下面工具栏 1 显示 0 隐藏
      // toolbar: 1,
      navbar: false,       //关闭缩略图
      fullscreen: false,   //播放全屏
      loop: false,         //是否循环 上一个 下一个
      minWidth:'',
      minHeight:'',
      toolbar: {
        zoomIn: { size: 'large' },  //放大
        zoomOut: { size: 'large' }, //缩小
        reset: { size: 'large' },   //重置
        prev: { show: true, size: 'large', }, //上一张
        play: { show: false, size: 'large', },  //播放
        next: { show: true, size: 'large', },  //下一张
        rotateLeft: { size: 'large' },  //左旋转
        rotateRight: { size: 'large' }, //右旋转
        flipHorizontal: { size: 'large' },  //左右翻转
        flipVertical: { size: 'large' },    //上下翻转
      },
    };
    return (
      <div className='chat_item'>
        {/* 提问 */}
        {data.question?.trim() !== "" &&
          <div className='chat_me_bg'>
            <div className='chat_me'>
              <Avatar className='chat_me_icon'>{user.shortName}</Avatar>
              {/* <div className='chat_me_icon'>C</div> */}
              <div className='chat_me_msg'>{data.question}</div>
            </div>
          </div>
        }
        {/* 回答 */}
        <div className='chat_bot_bg'>
          <div className='chat_bot'>
            <div className='chat_bot_icon'>
              <img src={BotAvt} alt=''></img>
            </div>
            <div className='chat_box_bubble'>
              <div className='chat_bot_msg'
                onClick={(e, a) => {
                  this.msgClicked(e, data)
                }}
              >
                {data.chatTemplater_ID > 0 &&
                  <div className="chat_bot_helper">
                    <div className='chat_emoji'>{data.chatTemplater_Img}</div>
                    {data.chatTemplater_Title}
                  </div>
                }
                <RcViewer
                  options={options}
                // ref={(v) => {
                //   console.log(v);
                //   // setPreview(v);
                // }}
                >
                  {data.answer?.trim() !== "" &&
                    <ReactMarkdown
                      className='markdown'
                      rehypePlugins={[
                        // 推荐在此处改解析问题
                        // () => {
                        //   return (tree) => {
                        //     console.log(tree)
                        //     tree.children.forEach((node) => {
                        //       if (node.type === 'delete') {
                        //         // node.type = 'text';
                        //       }
                        //     });
                        //   };
                        // },
                        rehypeRaw
                      ]}
                      remarkPlugins={[remarkGfm]}
                      components={{
                          'div': ({ node, ...props }) => {
                              // console.log(node, props)
                              if (props.className === 'file_links') {

                                  let aSize = 0;
                                  for (var i in props.children) {
                                      if (props.children[i].type === 'a') {
                                          aSize++;
                                        }
                                  }


                                  return (
                                      /*<div>
                                          <Button onClick={e=>this.showLinks(e)}>cankaowendang</Button>
                                          <div {...props} style={{ display:"none" }} />
                                      </div>    */
                                      <Collapse
                                          size="small"
                                          items={[
                                              {
                                                  label: <span style={{ width: '20px', color: 'darkgray' }}>参考文档 · {aSize}</span>,
                                                  children: <div {...props} />

                                              }
                                          ]}
                                          bordered={false}>
                                      </Collapse>
                                  )
                              }
                              return <div {...props} />;

                          },
                        'em': ({ node, ...props }) => {
                          // console.log(node, props)
                          if (props.children[0] && typeof props.children[0] === 'string' && props.children[0].startsWith('^sup')) {
                            return <sup>{props.children[0].substring(4)}</sup>
                          }
                          if (props.children[0] && typeof props.children[0] === 'string' && props.children[0].startsWith('~sub')) {
                            return <sub>{props.children[0].substring(4)}</sub>
                          }
                          return <em {...props} />
                        },
                        'img': ({ node, ...props }) => {
                          // console.log(node, props, this.state.imgLoadedArr)
                          if ((this.state.imgLoadedArr.includes(props.src) || props.src.startsWith('data:image') || props.src.startsWith('blob:'))) {
                            return <img src={props.src} alt={props.alt || ""}/>
                          }
                          return <LazyImage {...props} onLoaded={(src)=>{ this.onLoaded(src) }}/>
                          // return <LazyImage {...props} />
                        },
                        // 当出现删除标签时，将其转换为文本 （不推荐）
                        // 'del': ({ node, ...props }) => {
                        //   console.log(node, props)
                        //   return <>
                        //     ~<span {...props}/>~
                        //   </>
                        // },
                        pre({ children }) {
                          const match = /language-(\w+)/.exec(children[0]?.props?.className || '')
                          return <div className='chat_code_box'>
                            <div className='chat_code_header'>
                              <div>{match?.[1]}</div>
                              <Button
                                onClick={()=>{ 
                                  navigator.clipboard.writeText(children[0].props.children[0]);
                                  message.success('Copied to clipboard.')
                                }}
                                icon={<CopyOutlined />}
                                size='small'
                                type='text'
                              >Copy code</Button>
                            </div>
                            {children}
                          </div>
                        },
                        code({node, inline, className, children, ...props}) {
                          const match = /language-(\w+)/.exec(className || '')
                          // console.log(node, inline, className, children, props)
                          // console.log(match)
                          return !inline ? (
                            <SyntaxHighlighter
                              {...props}
                              children={String(children).replace(/\n$/, '')}
                              style={coldarkDark}
                              language={match?.[1] || undefined}
                              PreTag="div"
                            />
                          ) : (
                            <code {...props} className={className}>
                              {children}
                            </code>
                          )
                        }
                      }}
                    >
                      {data.answer}
                      {/* {`<p>${data.answer}</p>`}   */}
                    </ReactMarkdown>
                  }
                </RcViewer>
                {this.props.responeseType === 'text' && data.suggestQuestions?.length > 0 ?
                  <div className='chat_bot_questions'>
                    <div className='chat_bot_ques_title'>{t('chat.Follow-ques')}</div>
                    {data.suggestQuestions?.map((it, id) => {
                      return (
                        <div className='chat_bot_ques_item' key={id} onClick={() => {
                          console.log(it.text_Type)
                          if (it.text_Type === "Forms" && it.forms) {
                            this.props.getForm?.(it.forms)
                          } else {
                            this.props.search?.(it.question, it.number)
                          }
                        }} title={it.question}>
                          <div>{it.question}</div>
                        </div>
                      )
                    })}
                  </div>
                  : null}
                {/* 表单 */}
                {data.searchType === "form" &&
                  <ChatForm fData={data.formData} init={() => { this.props.scrollToBottom?.() }}></ChatForm>
                }
                {/* 通用模块选择列表 */}
                {data.searchType === "guider" &&
                  <>
                    <div className='chat_guider_detail_box'>
                      {data.guiderData?.map((it, id) => {
                        if (id >= 3 && !this.state.showAllGuider) return;
                        return <div key={id} className='guider_item' onClick={() => { this.props.selectGuider?.(it.id) }} title={it.question}>
                          <div className='gi_lt'>
                            <img src={it.product_Image}></img>
                          </div>
                          <div className='gi_gt'>
                            <div className='gi_title'>{it.name}</div>
                            <div className='gi_des'>{it.description}</div>
                          </div>
                        </div> 
                      })}
                    </div>
                    {!this.state.showAllGuider && data.guiderData?.length >= 3 &&
                      <div className='chat_guider_showmore' onClick={()=>{ this.setState({showAllGuider: true}) }}>
                        展开更多 <DownOutlined />
                      </div>
                    }
                  </>
                }
              </div>
              {/* projectType === "File" &&  */}
              {data.source_files?.length > 0 ?
                <div className='chat_bot_info'>
                  <div className='chat_bot_info_header'>
                    Where did this answer come from?
                    <InfoCircleOutlined className="get_file" onClick={() => { this.getFileInfo(data) }} />
                  </div>
                  {data.getFile ?
                    <div className='chat_bot_info_body'>
                      {/* <div className='chat_bot_info_title'>Sources {`${data.source_files.length}/${data.source_files.length}`}</div>
                        {data.source_files.length === 0 ? 
                          <LoadingOutlined />
                        :null} */}
                      {data.source_files.sort((a, b) => a.index - b.index).map((it, id) => {
                        return (
                          <div className='chat_bot_info_item' key={id}
                            onClick={() => { this.props.viewFile?.(it) }}
                          >{it.index}. {it.filename || `file_${it.index}`}</div>
                        )
                      })}
                    </div>
                    : null}
                </div>
              : null}
                    </div>
                </div>
                <div className='chat_bot_addition'>

                    <div className='cad_time'>{moment(data.cDate).calendar()}</div>
                    <div><Button
                        onClick={() => { this.reGenerateAnswer() }}
                        icon={<ReloadOutlined />}
                        size='small'
                        type='text'
                        style={{ 'font-size': '.825rem', 'color': 'rgb(88 88 88)' }}
                      
                    >重新生成</Button></div>
            {/*{!data.searchType && (!isHistory || data.isFeedBack ) &&*/}
            {/*  <div className='chat_like_box'>*/}
            {/*    {(!data.isFeedBack || data.feedback?.feedback_type) &&*/}
            {/*      <LikeOutlined onClick={()=>{ this.setLike(true) }} className={`${data.feedback?.feedback_type && "active"}`}/>*/}
            {/*    }*/}
            {/*    {(!data.isFeedBack || !data.feedback?.feedback_type) &&*/}
            {/*      <DislikeOutlined onClick={()=>{ this.setLike(false) }} className={`${data.feedback?.feedback_type===false && "active"}`}/>*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*}*/}
          </div>
        </div>
        {feedback.feedback_type === false && (!isHistory || feedback.feedback_Reason || feedback.remark) &&
          <div className='chat_bot_bg'>
            <div className='chat_bot'>
              <div className='chat_bot_icon'>
                <img src={BotAvt} alt=''></img>
              </div>
              <div className='chat_box_bubble'>
                <div className='chat_bot_msg dislike_box'>
                  <div className='dislike_title'>{t('chat.feedback.您选择差评的原因是：')}</div>
                  <div className='dislike_btns'>
                    <div className={`dis_btn ${feedback.feedback_Reason === '答非所问'&&'active'}`} onClick={()=>{ this.setFeedBack('答非所问', 'feedback_Reason') }}>{t('chat.feedback.答非所问')}</div>
                    <div className={`dis_btn ${feedback.feedback_Reason === '答案难懂'&&'active'}`} onClick={()=>{ this.setFeedBack('答案难懂', 'feedback_Reason') }}>{t('chat.feedback.答案难懂')}</div>
                    <div className={`dis_btn ${feedback.feedback_Reason === '不够简洁'&&'active'}`} onClick={()=>{ this.setFeedBack('不够简洁', 'feedback_Reason') }}>{t('chat.feedback.不够简洁')}</div>
                    <div className={`dis_btn ${feedback.feedback_Reason === '逻辑不清'&&'active'}`} onClick={()=>{ this.setFeedBack('逻辑不清', 'feedback_Reason') }}>{t('chat.feedback.逻辑不清')}</div>
                  </div>
                  <TextArea
                    value={feedback.remark}
                    onChange={(e)=>{ this.setFeedBack(e.target.value, 'remark') }}
                    placeholder={t('chat.feedback.如有其他意见，请输入')}
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                    className='dislike_textarea'
                  ></TextArea>
                  {!isHistory && !data.feedback?.remark?.trim() && !data.feedback?.feedback_Reason?.trim() &&
                    <Button
                      onClick={()=>{ 
                        this.saveFeedback();
                      }}
                      className='origen submit'
                      size='small'
                    >{t('chat.feedback.提交')}</Button>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default React.memo(QAModule);