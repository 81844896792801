import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './redux/actions'
import { bindActionCreators } from 'redux'
import asyncComponent from './AsyncComponent'
import { withRouter, Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Route } from 'react-router';
import AppLayout from './app/app';
import { Layout } from './components/Layout';
// import { Home } from './components/Home';
import { FetchData } from './components/templater/FetchData';
import { Counter } from './components/templater/Counter';
import { UsersRouter } from './components/users/UsersRouter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';


import './custom.css'

// const Plaza = asyncComponent(() => import('./app/plaza/plaza'))
const AskMe = asyncComponent(() => import('./app/chat/askMe'))
const AskKb = asyncComponent(() => import('./app/chat/askKb'))
// const PageFrame = asyncComponent(() => import('./app/iframe/pageFrame'))
const ChatSetting = asyncComponent(() => import('./app/general/chatSetting'))
const UsersList = asyncComponent(() => import('./app/users/usersList'))
const RoleList = asyncComponent(() => import('./app/users/roleList'))
const KnowledgeBase = asyncComponent(() => import('./app/knowledge/knowledgeBase'))
const KBManage = asyncComponent(() => import('./app/knowledge/kbManage'))
const PromptsLib = asyncComponent(() => import('./app/general/promptsLib'))
const BotManage = asyncComponent(() => import('./app/bot/botManage'))
const BotList = asyncComponent(() => import('./app/bot/botList'))
const HistoryManage = asyncComponent(() => import('./app/general/historyManage'))

class App extends Component {
  static displayName = App.name;

  state = {
    modeType: 'app',
    pathNameArr: [
        '/chat-official',
      '/chat-kb',
      '/askme',
      '/login',
      '/',
      '/new-users',
      '/knowledge-base',
      '/knowledge-base/manage',
      '/bots',
      '/bots/manage',
      '/general/prompt',
      '/general/history',
      '/general/azure-setting',
      '/system/role',
      '/system/user',
      '/square'
    ],
  }

  componentDidMount() {
    // 判断是否是app模式
    this.listenRouter(this.props.location, this.props.action);
    // 路由监听
    this.unlisten = this.props.history.listen((location, action) => { this.listenRouter(location, action) })
  }
  componentWillUnmount() {
    this.unlisten();
  }

  listenRouter(location, action) {
    // console.log("路由监听：", this.state.pathNameArr.includes(location.pathname));
    if (this.state.pathNameArr.includes(location.pathname)) {
      this.setState({
        modeType: 'app',
      })
    } else {
      this.setState({
        modeType: 'manage',
      })
    }
  }

  render() {
    // console.log(this.state.modeType)
    return (
      <>
        {this.state.modeType === 'app' ?
          <AppLayout {...this.props}>
            <Switch>
              {/* <Route path="/" render={(props) => <AskMe official={true} {...this.props} />} /> */}
               {/* <Route path="/chat-official" render={(props) => <AskMe official={true} {...this.props} />} />*/}
               <Route path="/chat-official" render={(props) => <AskKb official={true} {...this.props} />} />
              {/*<Route exact path='/square' render={(props) => <Plaza {...this.props} />} />*/}
              <Route exact path='/knowledge-base' render={(props) => <KnowledgeBase {...this.props} />} />
              <Route path='/knowledge-base/manage' render={(props) => <KBManage {...this.props} />} />
              <Route exact path='/bots' render={(props) => <BotList {...this.props} />} />
              <Route path='/bots/manage' render={(props) => <BotManage {...this.props} />} />
              <Route path='/askme' render={(props) => <AskMe {...this.props} />} />
              <Route path='/general/history' render={(props) => <HistoryManage {...this.props} />} />
              <Route path='/general/prompt' render={(props) => <PromptsLib {...this.props} />} />
              <Route path="/general/azure-setting" render={(props) => <ChatSetting {...this.props} />} />
              <Route path='/system/user' render={(props) => <UsersList {...this.props} />} />
              <Route path='/system/role' render={(props) => <RoleList {...this.props} />} />
            </Switch>
          </AppLayout> :
          <Layout>
            {/* <Route exact path='/' component={Home} /> */}
            <Route path='/counter' component={Counter} />
            <AuthorizeRoute path='/fetch-data' component={FetchData} />
            <AuthorizeRoute path='/users' component={UsersRouter} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
          </Layout>
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return state
}
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(actions, dispatch)
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App)));
