/// <reference path="chatfrom.js" />
import React from 'react';
import fetchAxios from '../../fetch/axios';
import '../../style/chat.less';
import moment from 'moment'
import HeaderAvt from '../../images/chatbot_header_icon.svg';
import BotAvt from '../../images/chatbot_avatar.png';
import ChatSend from '../../images/icon/ic_chat_send.svg';
import { Input, Avatar, Button, message, AutoComplete, Select,Upload } from 'antd';
import { SearchOutlined, RightOutlined, RedoOutlined, UnorderedListOutlined, LineOutlined, CopyOutlined, CloseOutlined, UploadOutlined, PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import FilePreview from "../components/filePreview";
// import ChatHelper from '../components/chatHelper';
import QAModule from '../components/qaModoule';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import authService from '../../components/api-authorization/AuthorizeService'
const { TextArea } = Input;

class Chat extends React.Component {
  state = {
    pId: "",
    chatMsg: [],
    askMsg: "",
    isGenerating: false,
    tempAsk: "",
    tempAnswer: "",
    tempAnswerArr: [],
    chatObj: {
      subjectGUID: "",
    },
    newId: "",
    isNew: false,
    subId: "",
    isAddingMsg: false,
    isTyping: false,
    pSetting: {},
    defaultQuestion: [],
    allQuestions: [], //所有问题

    showMoreQuestion: false, //是否显示更多问题
    isGettingDefaultQuestion: false, //是否正在获取默认问题
    imgLoadedArr: [], //图片是否加载完成

    guideMode: false, // 导览模式
    guideId: 0, // 选中的导览模式id
    KBData: [], // 导览数据

    prompt: undefined, // 选中的prompts
      promptsList: [], // prompts列表
      isUploading: false,
      uploadedFiles: [],
      hoverFilename: null, // 用于追踪当前悬停的文件名
  }
  childRef = React.createRef();
  componentDidMount() {
    this.getPromptsList();
  }
  static getDerivedStateFromProps(props, state) {
    if (props.chatObj.subjectGUID !== state.chatObj.subjectGUID) {
      return {
      }
    }
    if (props.pId !== state.pId) {
      return {
      }
    }
    return null
  }
  componentDidUpdate() {
    if (this.props.chatObj.subjectGUID !== this.state.chatObj.subjectGUID) {
      this.setState({ chatObj: this.props.chatObj, isGenerating: false }, () => {
        // 清空历史记录
        // console.log(this.state.chatObj.subjectGUID)
        if (this.state.chatObj.subjectGUID) {
          // 如果不是新建转已有，则调用历史记录，新建转已有则不调用 (防止闪烁)
          if (!this.props.chatObj?.isCreated) {
            // this.setState({chatMsg:[]})

            // 有id获取历史记录 默认问题
            this.getHistory()

            // 如果不是通用项目，则获取默认问题
            if (this.props.pId !== "general") {
              // this.getDefaultQuestion(this.state.chatObj.subjectGUID);
            }
          }
          this.setState({ newId: "" })

        } else {
          // 无id生成id
          let newId = this.getGuid();
          this.setState({ chatMsg: [], newId, });
          // 如果不是通用项目，则获取默认问题
          if (this.props.pId !== "general") {
            // this.getDefaultQuestion(newId);
          } else {
            this.setState({ guideId: 0 })
          }
        }
      })
    }
    if (this.props.pId !== this.state.pId) {
      // console.log("pId", this.props.pId)
      this.setState({ pId: this.props.pId, guideMode: this.props.pId === "general" }, () => {
        if (this.props.pId !== "general") {
          // 不是通用模式时，获取项目配置
          this.getProjectConfig();
          // this.getAllQuestions(this.props.pId);
        } else {
          this.getKnowledgeBase();
        }
      });
    }
  }
  // 获取唯一ID
  getGuid() {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }
  keyListen(e) {
    if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey && !e.metaKey) {
      setTimeout(() => {
        this.search();
      });
    }
  }
    // 获取prompts列表
    getPromptsList() {
        this.setState({ promptsList: [] })

        fetchAxios.get(`/api/Prompt/Libary?tab=2&page=1&size=100&topic=Chat&activity=Chat`).then((res) => {
            if (res && res.success) {
                res.data.forEach((item, index) => {
                    item.key = item.id;
                    item.label = item.title;
                    item.value = item.id;
                })
                this.setState({ promptsList: res.data, pageSum: res.totalCount })
            }
        })
    }
    // 选择prompts
    selectPrompts(e) {
        // console.log(e)
        this.setState({
            prompt: e,
        })
    }
  // 获取机器人所有问题
  getAllQuestions(pId) {
    fetchAxios.get(`/api/project/${pId}/faq`).then((res) => {
      if (res && res.success) {
        let allQuestions = res.data;
        allQuestions.forEach((item, index) => {
          item.key = index;
          item.label = item.question;
          item.value = item.question;
        })
        this.setState({
          allQuestions: res.data
        })
      }
    })
  }
  // 选择问题
  selectQuestion(e, item) {
    // console.log(e, item)
    this.search(e, `${item.id}`)
  }
  // 获取项目配置
  getProjectConfig() {
    if (!this.state.pId) return;
    fetchAxios.get(`/api/Project/Setting/${this.state.pId}`).then((res) => {
      if (res && res.success) {
        if (res.data?.project_name) {
          document.title = "NovaGPT - " + res.data.project_name;
        }
        this.setState({
          pSetting: res?.data || {}
        })
      }
    })
  }
  // 获取历史
  getHistory() {
    // console.log("obj", this.state.chatObj);
    this.setState({ chatMsg: [] })
    // 查询历史记录
    fetchAxios.get(`/api/conversations/${this.state.chatObj.subjectGUID}`).then((res) => {
      if (res && res.success) {
        this.setState({
          chatMsg: JSON.parse(JSON.stringify(res.data)),
        }, () => {
          this.scrollToBottom();
        })
      } else {
        this.setState({ chatMsg: [] })
      }
    })
  };
  // 获取默认问题
  getDefaultQuestion(sId, type) {
    if (this.state.isGettingDefaultQuestion) return;
    this.setState({ isGettingDefaultQuestion: true })
    // type=all 全部问题 type=refresh 刷新 type=default 默认
    // refresh时，额外传入最后一条问题的id
    let str = type === "refresh" ? `?type=refresh&id=${this.state.defaultQuestion[this.state.defaultQuestion.length - 1]?.sort_question}` :
      type === "all" ? "?type=all" : "?type=default";
    // this.setState({defaultQuestion: []})
    fetchAxios.get(`/api/Project/${this.state.pId}/SuggestQuestions/${sId}${str}`).then((res) => {
      if (res && res.success && res.data) {
        this.setState({
          defaultQuestion: res.data,
          isGettingDefaultQuestion: false
        });
        if (type === "all") {
          this.setState({
            showMoreQuestion: true
          });
        } else {
          this.setState({
            showMoreQuestion: false
          });
        }
      }
    })
  }
  // 收起默认问题
  closeQuestions() {
    let num = this.state.pSetting?.firstQuestionShowNum || 5;
    this.setState({
      defaultQuestion: this.state.defaultQuestion.slice(0, num),
      showMoreQuestion: false
    })
  }

  // 获取导览数据
  getKnowledgeBase() {
    fetchAxios.get(`/api/KB_Categorie`).then(res => {
      if (res.success && res.data) {
        res.data.forEach(item => {
          item.label = item.categoryName;
          item.key = item.categoryId;
        })
        this.setState({ KBData: res.data })
      }
    })
  }
  // 选择导览分类
  selectCategory(item) {
    this.setState({ isGenerating: true })
    let answer = "请问你想了解以下哪款产品的信息？"
    // this.typeWriter(answer, 50, ()=>{
    let resObj = {
      "question": item.categoryName,
      "answer": answer,
      "cDate": moment().format(),
      "suggestQuestions": [],
      "source_files": [],
      "searchType": "guider",
      "guiderData": item.items,
    };
    this.setState({
      chatMsg: this.state.chatMsg.concat(resObj),
      isGenerating: false,
    })
    // })
  }

  getCookie(name) {
    let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    arr = document.cookie.match(reg)
    return arr ? unescape(arr[2]) : null;
  }
  resetChat() {
    this.setState({
      tempAnswerArr: [],
      tempAnswer: '',
        isGenerating: false,
        uploadedFiles: [],
    })
    // if (this.state.isNew) {
    //   this.props?.creating(this.state.subId);
    //   this.setState({isNew: false, subId: ""});
    // }
  }
  // 查询问题
  async search(askStr, questionid, helperId, helpItem, guideSettings) {
    // 通用模式必须先选中向导才能问答
    // 通过guideSettings字段来判断是否获取向导配置
    if (this.state.guideMode && this.state.guideId === 0) return;

    // 传了askStr则为特殊问答，否则为用户输入问题
      if ((!askStr && !this.state.askMsg.trim() && !helperId && !guideSettings && !this.state.uploadedFiles.length) || this.state.isGenerating) return;

    let askMsg = askStr || this.state.askMsg.trim();
      let files = "";
      if (this.state.uploadedFiles.length > 0) {        
          for (var i = 0; i < this.state.uploadedFiles.length; i++) {
              files += "【" + this.state.uploadedFiles[i].filename + "】";
          }
      }
      let tempAskMsg = files + askMsg;

    // 初始化回复模块
    this.setState({
      isGenerating: true,
      askMsg: "",
      tempAsk: tempAskMsg,
      tempAnswer: "Hang in there! I'm thinking...",
    }, () => {
      this.scrollToBottom();
    });

    let searchObj = {
      "role": "user",
      "content": askMsg,
      "questionid": questionid || "", // 选中默认问题时的id
      "userId": this.props.uId || "",
       "userName": this.props.uName || ""
    }
    //助手问答，有askMsg则针对askMsg回复，无则针对上一条问题回复
    if (helperId) {
      searchObj["templaterid"] = helperId;
      }
      if (this.state.prompt) {
          searchObj["templaterid"] = this.state.prompt;
      }
    
      if (this.state.uploadedFiles.length>0) {
          searchObj["uploadedFiles"] = this.state.uploadedFiles;
      }
    let subId = this.state.chatObj.subjectGUID || this.state.newId;
    this.setState({ isNew: subId === this.state.newId, subId });

    let pId = this.props.pId;

    // 通用模式使用向导Id
    if (this.props.pId === "general") {
      pId = this.state.guideId;
    }

    //console.log(this.props.responeseType)
    if (this.props.responeseType === 'text') {
      // 普通接口
      fetchAxios.post(`/api/${this.props.official ? "" : `ask/${pId}/`}Chat/${subId}${guideSettings ? "/settings" : ""}`, searchObj).then((res) => {
        if (res) {
          this.setState({ tempAnswer: "" })

          // 先处理标注内容
          this.typeWriter(res.answer, 30, () => {
            // 新建则父组件重新获取列表
            if (subId === this.state.newId) {
              this.props.creating?.(subId, pId);
            }
            // console.log(res?.suggestquestions)
            // 将回答输出
            let resObj = {
              "id": res.id,
              "bot_ID": pId,
              "question": this.state.tempAsk,
              "answer": this.state.tempAnswer,
              "cDate": moment().format(),
              "suggestQuestions": res?.suggestquestions || [],
              "source_files": res?.source_files || [],
            };
            // console.log(resObj)
            this.setState({
              chatMsg: this.state.chatMsg.concat(resObj),
              tempAsk: "",
              tempAnswer: "",
              isGenerating: false,
            }, () => {
              // console.log(this.state.chatMsg)
            })
          });
        }
      })
    } else {
      const token = await authService.getAccessToken();

      // 流式输出
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + token,
        },
        responseType: 'stream',
        body: JSON.stringify(searchObj)
      };
      fetch(`/api/${this.props.official ? "" : `Project/${pId}/`}Chat/${subId}`, requestOptions).then((response) => {
        const reader = response.body.getReader();
        let isDone = false;
        this.setState({ tempAnswer: "" })

        let arrTemp = new Uint8Array([]);
        const readChunk = () => {
          reader.read().then(({ value, done }) => {
            // 切换会话，重置
            if (subId !== (this.state.chatObj.subjectGUID || this.state.newId)) {
              this.resetChat();
              return;
            }
            // console.log("=========================")
            // console.log(arrTemp)
            // console.log(value, done)
            let mergedArray;
            if (value) {
              mergedArray = new Uint8Array(arrTemp.length + value?.length);
              mergedArray.set(arrTemp);
              mergedArray.set(value, arrTemp.length)
            } else {
              mergedArray = new Uint8Array(arrTemp.length);
              mergedArray.set(arrTemp);
            }
            let text = new TextDecoder('utf-8').decode(mergedArray);
            if (value) {
              text = text?.slice(0, -2);
              let brforeCode = new TextEncoder('utf-8').encode(text);
              // console.log(brforeCode)
              arrTemp = mergedArray.subarray(brforeCode.length);
            }
            // console.log(text)

            this.setState({ isAddingMsg: true }, () => {
              this.setState({
                tempAnswerArr: this.state.tempAnswerArr.concat(text),
                isAddingMsg: false,
              })
            })
            if (done) {
              isDone = true;
              return;
            }
            readChunk();
          }).catch((err) => {
            console.error(`Error: ${err}`);
          });
        };
        readChunk();
        // 打印输出
        let typeWriterTimer = setInterval(() => {
          // 切换会话，重置
          if (this.state.subId !== (this.state.chatObj.subjectGUID || this.state.newId)) {
            this.resetChat();
            clearInterval(typeWriterTimer)
            return;
          }
          // 如果不在打印，则开始打印下一段 
          if (!this.state.isTyping) {
            let tempArr = this.state.tempAnswerArr;
            let firstItem = tempArr.shift();
            if (firstItem) {
              this.setState({
                tempAnswerArr: tempArr,
                isTyping: true
              });
              this.typeWriter(firstItem, 30);
            }
          }
          // 完成打印
          if (this.state.tempAnswerArr.length === 0 && !this.state.isAddingMsg && !this.state.isTyping && isDone) {
            // 若为新建则父组件重新获取列表
            if (subId === this.state.newId) {
              this.props.creating?.(subId);
            }
            clearInterval(typeWriterTimer);

            // 将回答输出
            let resObj = {
              "question": this.state.tempAsk,
              "answer": this.state.tempAnswer,
              "cDate": moment().format(),
              "searchType": "stream",
            };
            if (helperId) {
              resObj["chatTemplater_ID"] = helperId;
              resObj["chatTemplater_Img"] = helpItem.img;
              resObj["chatTemplater_Title"] = helpItem.title;
            }
            // console.log(resObj)
            this.setState({
              chatMsg: this.state.chatMsg.concat(resObj),
              tempAsk: "",
              tempAnswer: "",
              isGenerating: false,
            })
          }
        }, 100);
      })
    }
     //重置文件上传列表
      this.setState({
          uploadedFiles: [],
        
      })
  }
  // 打字机效果
  typeWriter(text, speed, callback) {
    // console.log(text)
    let sum = 0;
    const intervalId = setInterval(() => {
      // 切换会话，重置
      if (this.state.subId !== (this.state.chatObj.subjectGUID || this.state.newId)) {
        this.resetChat();
        clearInterval(intervalId);
        this.setState({ isTyping: false })
        return;
      }
      let step = Math.floor(Math.random() * (6)) + 2;
      if (sum < text.length) {
        // 检测图片、视频或音频标签
        const mediaRegex = /<img[\s\S]*?>|<video[\s\S]*?>|<audio[\s\S]*?>/g;
        const match = mediaRegex.exec(text.slice(sum));
        if (match?.index <= step) {
          // 直接输出图片标签
          const imgTag = match[0];
          this.setState({ tempAnswer: this.state.tempAnswer + text.slice(sum, sum + match.index) + imgTag }, () => { console.log(this.state.tempAnswer) });
          sum = sum + match.index + imgTag.length;
        } else {
          // 输出文字
          this.setState({ tempAnswer: this.state.tempAnswer + text.slice(sum, sum + step) },
            // () => { console.log(this.state.tempAnswer) }
          )
          sum += step;
        }
      } else {
        clearInterval(intervalId);
        this.setState({ isTyping: false })
        if (callback) {
          callback()
        }
      }
    }, speed);
  }
  // 滚动到底部
  scrollToBottom() {
    let ele = document.querySelector(".cp_chat_main");
    if (ele) ele.scrollTop = ele.scrollHeight;
  }
  viewFile(fileInfo) {
    this.childRef.current.getFile(fileInfo.fileid, fileInfo.filename);
  }
  // 助手问答
  helperSearch(id, helper) {
    // console.log(id);
    this.search("", "", id, helper)
  }
  getForm(fData) {
    // console.log(fData);
    let resObj = {
      "question": "",
      "answer": "",
      "cDate": moment().format(),
      "suggestQuestions": [],
      "source_files": [],
      "searchType": "form",
      "formData": fData,
    };
    this.setState({ chatMsg: this.state.chatMsg.concat(resObj), })
  }
  closeChatbox() {
    window.parent?.NovaGPT?.hide();
  }
  
    handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        if (this.state.uploadedFiles.length >= 5) {
            message.error("最多上传五个文件");
            return;
        }

        this.setState({ isUploading: true });

        // 创建FormData对象并附加文件
        let formData = new FormData();
        formData.append('file', file);

        // 使用fetchAxios发送文件
        try {
            const response = await fetchAxios.post('/api/Chat/uploadfile', formData, {
                headers: {
                    // 注意：当您使用FormData时，不要设置Content-Type头，浏览器会自动设置
                }
            });
           // console.log( response);
            // 检查响应
            if (response.success) {
                // 更新状态，假设响应包含文件的路径            
                this.setState(state => ({
                    uploadedFiles: [...state.uploadedFiles, response.data],
                    isUploading: false
                }));

            } else {
                // 处理错误情况
                message.error(response.message);
                this.setState({ isUploading: false });
            }
        } catch (error) {
            message.error(`file upload failed.`);
            this.setState({ isUploading: false });
        }
    };

    // 删除已上传的文件
    handleDeleteFile = fileName => {
        this.setState(state => ({
            uploadedFiles: state.uploadedFiles.filter(data => data.realFilename !== fileName)
        }));
        // 发送请求到服务器以删除文件
    };

    // 鼠标进入文件名时的处理函数
    handleMouseEnter = (filename) => {
        this.setState({ hoverFilename: filename });
    };

    // 鼠标离开文件名时的处理函数
    handleMouseLeave = () => {
        this.setState({ hoverFilename: null });
    };

  render() {
    // console.log(this.props)
    const { user } = this.props.reducer;
      const { t } = this.props;
      const { isUploading, uploadedFiles } = this.state;

    return (
      <div className={`cp_chat ${this.props.isEmbed && "cp_chat_embed"}`}>
        {this.props.isEmbed &&
          <div className='cp_chat_header'>
            <div className='cp_chat_hicon'>
              <img src={HeaderAvt} alt=''></img>
            </div>
            <div className='cp_chat_hmain'>
              <div className='cp_chat_title'>
                {t('艾罗能源机器人')}
              </div>
              <div className='cp_chat_hdes'>
                {t('在线')}
              </div>
            </div>
            <CloseOutlined onClick={() => { this.closeChatbox() }} />
          </div>
        }
        <div className='cp_chat_main'>
          {this.state.pSetting?.firstMessage && !this.state.guideMode &&
            <div className='chat_item'>
              {/* 问候语  */}
              <div className='chat_bot'>
                <div className='chat_bot_icon'>
                  <img src={BotAvt} alt=''></img>
                </div>
                <div className='chat_box_bubble'>
                  <div className='chat_bot_msg pre_line'>
                    {this.state.pSetting.firstMessage}
                  </div>
                </div>
              </div>
              {/* 默认问题 */}
              {this.props.responeseType === 'text' && this.state.defaultQuestion.length > 0 &&
                <div className='chat_bot'>
                  <div className='chat_bot_icon'>
                    <img src={BotAvt} alt=''></img>
                  </div>
                  <div className='chat_box_bubble'>
                    <div className='chat_bot_msg pre_line'>
                      <div className='chat_bot_opt'>
                        <RedoOutlined onClick={() => { this.getDefaultQuestion(this.state.chatObj.subjectGUID || this.state.newId, 'refresh') }} />
                        {this.state.showMoreQuestion ?
                          <LineOutlined onClick={() => { this.closeQuestions() }} /> :
                          <UnorderedListOutlined onClick={() => { this.getDefaultQuestion(this.state.chatObj.subjectGUID || this.state.newId, 'all') }} />
                        }
                      </div>
                      <div>{this.state.pSetting?.questionShowMessage || "Follow-up questions:"}</div>
                      <div className='chat_bot_questions'>
                        {this.state.defaultQuestion.map((it, id) => {
                          return (
                            <div
                              className='chat_bot_ques_item'
                              key={id}
                              onClick={() => {
                                // console.log(it)
                                if (it.text_Type === "Forms" && it.forms) {
                                  this.getForm(it.forms)
                                } else {
                                  this.search(it.question, it.number)
                                }
                              }}
                              title={it.question}
                            >
                              <div>{it.question}</div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
          {this.state.guideMode &&
            <div className='chat_item'>
              {/* 向导模块 */}
              <div className='chat_bot'>
                <div className='chat_bot_icon'>
                  <img src={BotAvt} alt=''></img>
                </div>
                <div className='chat_box_bubble'>
                  <div className='chat_bot_msg pre_line'>
                    你好！我是NovaTech智能机器人。你想了解哪些政策和问题都可以向我提问，我会尽力帮你查找答案！
                  </div>
                </div>
              </div>
              {/* 向导分类 */}
              <div className='chat_guider_nav'>
                {this.state.KBData.map((item, index) => {
                  return (
                    <div className='cgn_item' key={index} onClick={() => {
                      this.selectCategory(item);
                      setTimeout(() => {
                        this.scrollToBottom();
                      });
                    }}>
                      {item.categoryName}
                    </div>
                  )
                })}
              </div>
            </div>
          }
          {/* 问答记录 */}
          {this.state.chatMsg.map((item, index) => {
            return (
              <QAModule
                key={index}
                data={item}
                index={index}
                {...this.props}
                search={(q, n) => { this.search(q, n) }}
                getForm={(fData) => { this.getForm(fData) }}
                viewFile={(e) => { this.viewFile(e) }}
                scrollToBottom={() => { this.scrollToBottom() }}
                selectGuider={(id) => {
                  this.setState({ guideId: id }, () => {
                    // console.log("=====>", this.state.guideId)
                    this.search("", "", "", "", true);
                    // this.getAllQuestions(id);
                  })
                }}
                // pId={this.state.pId}
                uId={this.props.uId}
                uName={this.props.uName}
              // guideId={this.state.guideId}
              ></QAModule>
            )
          })}
          {/* 生成回答中 */}
          {this.state.isGenerating ?
            <div className='chat_item'>
              {this.state.tempAsk.trim() !== "" &&
                <div className='chat_me'>
                  <Avatar className='chat_me_icon'>{user.shortName}</Avatar>
                  {/* <div className='chat_me_icon'>C</div> */}
                  <div className='chat_me_msg'>{this.state.tempAsk}</div>
                </div>
              }
              <div className='chat_bot'>
                <div className='chat_bot_icon'>
                  <img src={BotAvt} alt='' ></img>
                </div>
                {/* <div className='chat_bot_msg'>{this.state.tempAnswer}</div> */}
                <div className='chat_box_bubble'>
                  <div className='chat_bot_msg generating'>
                    <ReactMarkdown
                      className='markdown'
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        'em': ({ node, ...props }) => {
                          // console.log(node, props)
                          if (props.children?.[0] && typeof props.children?.[0] === 'string' && props.children?.[0].startsWith('^sup')) {
                            return <sup>{props.children[0].substring(4)}</sup>
                          }
                          if (props.children?.[0] && typeof props.children?.[0] === 'string' && props.children?.[0].startsWith('~sub')) {
                            return <sub>{props.children[0].substring(4)}</sub>
                          }
                          return <em {...props} />
                        },
                        // 'img': ({ node, ...props }) => {
                        //   // console.log(node, props)
                        //   return <LazyImage {...props} />
                        // },
                        pre({ children }) {
                          const match = /language-(\w+)/.exec(children?.[0]?.props?.className || '')
                          return <div className='chat_code_box'>
                            <div className='chat_code_header'>
                              <div>{match?.[1]}</div>
                              <Button
                                onClick={() => {
                                  navigator.clipboard.writeText(children?.[0]?.props?.children?.[0] || '');
                                  message.success('Copied to clipboard.')
                                }}
                                icon={<CopyOutlined />}
                                size='small'
                                type='text'
                              >Copy code</Button>
                            </div>
                            {children}
                          </div>
                        },
                        code({ node, inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || '')
                          return !inline ? (
                            <SyntaxHighlighter
                              {...props}
                              children={String(children).replace(/\n$/, '')}
                              style={coldarkDark}
                              language={match?.[1] || undefined}
                              PreTag="div"
                            />
                          ) : (
                            <code {...props} className={className}>
                              {children}
                            </code>
                          )
                        }
                      }}
                    >
                      {this.state.tempAnswer}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
              {/* <div className='cp_progress'>
                <div className='cp_pgs_bg'></div>
                <div className='cp_pgs_bar'>
                  <div className='cp_pgs_ani long'></div>
                  <div className='cp_pgs_ani short'></div>
                  <div></div>
                </div>
              </div> */}
            </div>
            : null}
        </div>
          <div className='cp_chat_ask'>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1.5rem' }}>
                    {/*<div></div>*/}
                    <Select
                        placeholder={"选择提示词"}
                        value={this.state.prompt}
                        onChange={(e) => { this.selectPrompts(e) }}
                        // bordered={false}
                        options={this.state.promptsList}
                        style={{ width: '100%' }}
                        allowClear
                    />
                </div>
          <div className='cp_chat_ask_main' onKeyDown={(e) => { this.keyListen(e) }}>
            <AutoComplete
              options={this.state.allQuestions}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              // onChange={(e, item)=>{
              //   console.log(e, item)
              //   // if (item.id) {
              //   //   this.selectQuestion(e, item)
              //   // }
              // }}
              onSelect={(e, item) => {
                console.log(e, item)
                if (item.id) {
                  this.selectQuestion(e, item)
                }
              }}
              // onSearch={(e, a)=>{ console.log(e, a) }}
              // this.search();
              defaultOpen={false}
              style={{ width: '100%' }}
              open={this.state.askMsg.trim() !== "" && !this.state.isGenerating}
            >
                        <div className='cp_chat_ask_top'>
                                                      
                                <input type="file" id="fileInput" style={{ display: 'none' }} onChange={this.handleFileChange} />
                                <PaperClipOutlined onClick={() => document.getElementById('fileInput').click()} />
                                {isUploading && <span>Uploading...</span>}
                            {/* 显示已上传的文件列表 */}
                            <div >
                                {uploadedFiles.map(file => (
                                    <div key={file.realFilename} className="file-item" onMouseEnter={() => this.handleMouseEnter(file.realFilename)}
                                        onMouseLeave={this.handleMouseLeave}>
                                        <span className="filename" title={file.filename} >{file.filename}</span> 
                                        {this.state.hoverFilename === file.realFilename && (
                                            <DeleteOutlined onClick={() => this.handleDeleteFile(file.realFilename)} style={{ fontSize: '12px', color: 'red', cursor: 'pointer' }} />
                                        )}
                                       
                                    </div>
                                ))}
                                
                            </div>
                            
                <TextArea
                  value={this.state.askMsg}
                  onChange={(e) => {
                    this.setState({ askMsg: e.target.value })
                  }}
                  placeholder={t('chat.Ask me')}
                  autoSize={{
                    minRows: 5,
                    maxRows: 5,
                  }}
                  disabled={this.state.isGenerating || (this.state.guideMode && !this.state.guideId)}
                />
                <div className='cp_chat_ask_enter' onClick={() => this.search()}>
                  <img src={ChatSend} alt=''></img>
                </div>
              </div>
            </AutoComplete>
            {/* <div className='cp_chat_ask_bottom'>
              <div className='cp_chat_ask_counter'>{this.state.askMsg.length}/1000</div>
              {this.props.responeseType === 'stream' &&
                <ChatHelper helperSearch={(id, helper)=>{ this.helperSearch(id, helper) }} t={this.props.t}></ChatHelper>
              }
            </div> */}
          </div>
        </div>
        {/* <div className='cp_chat_power' >
          <a href='https://www.novagpt.ai' target='__blank'>{t('chat.Powered')}</a>
        </div> */}
        <FilePreview ref={this.childRef}></FilePreview>
      </div>
    )
  }
}

export default Chat